<template>
  <div class="company">
    <el-breadcrumb class="breadCrumb"
                   separator="/">
      <el-breadcrumb-item>个人中心</el-breadcrumb-item>
      <el-breadcrumb-item>认证管理</el-breadcrumb-item>
      <el-breadcrumb-item>企业信息</el-breadcrumb-item>
    </el-breadcrumb>
    <p class="failTip"
       v-if="state=='2'">
      <img style="vertical-align: -2px;width: 16px;height: 16px"
           src="@/assets/img/sq_01@2x.png"
           alt=""> {{ failureMsg }}
    </p>
    <div v-if="commitState==false">
      <el-form class="cardInfo mt20"
               ref="form"
               :rules="rules"
               :model="form"
               label-width="166px">
        <el-header class="titleOne">基础信息</el-header>
        <el-row>
          <div class="infoState">
            <img width="120px"
                 height="106px"
                 v-show="state=='3'&&companyRole=='0'"
                 src="@/assets/img/auth/tg_01.png"
                 alt="">
            <img width="120px"
                 height="106px"
                 v-show="state=='2'&&companyRole=='0'"
                 src="@/assets/img/auth/rzsb_02.png"
                 alt="">
            <img width="120px"
                 height="106px"
                 v-show="state=='1'&&companyRole=='0'"
                 src="@/assets/img/auth/shz_01.png"
                 alt="">
          </div>
          <el-form-item label="营业执照："
                        prop="fontBusinessUrl">
            <el-upload :disabled="((state=='4'||state=='0'||state=='6')||companyRole!='0')?false:true"
                       class="avatar-uploader upImg"
                       action="#"
                       :auto-upload="false"
                       :on-change="(file, fileList) => {changeImgHead(file, fileList, type='1',index='1')}"
                       :show-file-list="false">
              <img v-if="form.fontBusinessUrl"
                   :src="form.fontBusinessUrl"
                   class="avatar">
              <div v-else
                   class="icon">
                <em></em>
                <i>上传营业执照</i>
              </div>
            </el-upload>
            <a href="#javascript:;"
               class="upImgTip"
               @click="lookVisible=true">查看示例</a>
            <p class="tips ml76">（请上传清晰的证照，系统会自动识别照片内信息，如信息有误可手动修改）</p>
          </el-form-item>
          <el-form-item label="企业名称："
                        prop="companyName">
            <el-input v-model.trim="form.companyName"
                      class="input450"
                      placeholder="请输入企业名称"
                      :disabled="((state=='4'||state=='0'||state=='6')||companyRole!='0')?false:true"></el-input>
          </el-form-item>
          <el-form-item label="统一信用代码证号："
                        prop="socCode">
            <el-input v-model="form.socCode"
                      class="input450"
                      placeholder="请输入统一信用代码"
                      :disabled="(((state=='4'||state=='0')&&companyRole=='0'&&flag!=true)||(companyRole!='0'&&false))?false:true"></el-input>
          </el-form-item>
          <el-form-item label="办公地址："
                        class="adress"
                        prop="workProvince">
            <el-select v-model="form.workProvince"
                       placeholder="请选择"
                       @change="getCity"
                       :disabled="((state=='4'||state=='0'||state=='6')||companyRole!='0')?false:true">
              <el-option v-for="(item,index) in province"
                         :label="item.name"
                         :value="item.id"
                         :key="item.id">
                <!--                {{item.id}}-->
              </el-option>
            </el-select>
            <el-select v-model="form.workCity"
                       placeholder="请选择"
                       @change="getDistrict"
                       :disabled="((state=='4'||state=='0'||state=='6')||companyRole!='0')?false:true">
              <el-option v-for="(item,index) in city"
                         :label="item.name"
                         :value="item.id"
                         :key="item.id">{{ item.name }}
              </el-option>
            </el-select>
            <el-select v-model="form.workDistrict"
                       placeholder="请选择"
                       :disabled="((state=='4'||state=='0'||state=='6')||companyRole!='0')?false:true">
              <el-option v-for="(item,index) in district"
                         :label="item.name"
                         :value="item.id"
                         :key="item.id">
                {{ item.name }}
              </el-option>
            </el-select>
            <el-input v-model.trim="form.workAddress"
                      class="input394"
                      placeholder="请输入详细地址"
                      :disabled="((state=='4'||state=='0'||state=='6')||companyRole!='0')?false:true"></el-input>
          </el-form-item>

          <el-form-item v-if="getbusinessRole=='1'"
                        label="核心企业名称：">
            <el-select style="width: 450px"
                       label="核心企业名称："
                       v-model="form.coreCompanyName"
                       placeholder="请选择核心企业名称"
                       filterable
                       clearable
                       :filter-method='getCoreCompany'
                       :disabled="((state=='4'||state=='0'||state=='6')||companyRole!='0')?false:true">
              <el-option v-for="(item,index) in coreName"
                         :label="item.coreCompanyName"
                         :value="item.coreCompanyName"
                         :key="index">{{ item.coreCompanyName }}
              </el-option>
            </el-select>
          </el-form-item>
        </el-row>
        <div v-if="getbusinessRole=='0'">
          <el-header class="titleOne mt20">法人信息</el-header>
          <el-row>
            <el-form-item class="inlineBlock"
                          label="法人证件："
                          prop="fontLegalUrl">
              <el-upload :disabled="((state=='4'||state=='0'||state=='6')||companyRole!='0')?false:true"
                         class="avatar-uploader upImg"
                         action="#"
                         :auto-upload="false"
                         :on-change="(file, fileList) => {changeImgHead(file, fileList, type='2',i='1')}"
                         :show-file-list="false">
                <img v-if="form.fontLegalUrl"
                     :src="form.fontLegalUrl"
                     class="avatar">
                <div v-else
                     class="icon">
                  <em></em>
                  <i>身份证头像页</i>
                </div>
              </el-upload>
            </el-form-item>
            <el-form-item class="inlineBlock"
                          prop="backLegalUrl">
              <el-upload :disabled="((state=='4'||state=='0'||state=='6')||companyRole!='0')?false:true"
                         class="avatar-uploader upImg"
                         action="#"
                         :auto-upload="false"
                         :on-change="(file, fileList) => {changeImgHead(file, fileList, type='2',i='2')}"
                         :show-file-list="false">
                <img v-if="form.backLegalUrl"
                     :src="form.backLegalUrl"
                     class="avatar">
                <div v-else
                     class="icon">
                  <em></em>
                  <i>身份证国徽页</i>
                </div>
              </el-upload>
              <a href="#javascript:;"
                 class="upImgTip"
                 @click="lookVisibleSave=true">查看示例</a>

            </el-form-item>
            <el-form-item>
              <p class="tips ml76">（请上传清晰的证照，系统会自动识别照片内信息，如信息有误可手动修改）</p>
            </el-form-item>
            <el-form-item label="手机号码："
                          prop="legalTel">
              <el-input v-model="form.legalTel"
                        class="input450"
                        placeholder="请输入手机号码"
                        :disabled="((state=='4'||state=='0'||state=='6')||companyRole!='0')?false:true"></el-input>
              <!--              <router-link v-if="state=='2'" :to="{path:'/aside'}">-->
              <el-button v-if="state=='0'||state=='4'||state=='6'"
                         @click="IdCheck(type='f')"
                         class="idBtn"
                         type="primary">身份核验
              </el-button>
              <!--              </router-link>-->
            </el-form-item>
            <!-- <el-form-item label="测试 法人姓名："
                          prop="legalName">
              <el-input v-model="form.legalName"
                        class="input450"
                        placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="测试 法人身份证号："
                          prop="legalIdNo">
              <el-input v-model="form.legalIdNo"
                        class="input450"
                        placeholder=""></el-input>
            </el-form-item> -->
          </el-row>

          <el-header class="titleOne mt20">操作员信息</el-header>
          <el-row>
            <el-form-item class="inlineBlock"
                          label="操作员证件："
                          prop="fontIdUrl">
              <el-upload :disabled="((state=='4'||state=='0'||state=='6')||companyRole!='0')?false:true"
                         class="avatar-uploader upImg"
                         action="#"
                         :auto-upload="false"
                         :on-change="(file, fileList) => {changeImgHead(file, fileList, type='2',i='21')}"
                         :show-file-list="false">
                <img v-if="form.fontIdUrl"
                     :src="form.fontIdUrl"
                     class="avatar">
                <div v-else
                     class="icon">
                  <em></em>
                  <i>身份证头像页</i>
                </div>
              </el-upload>
            </el-form-item>
            <el-form-item class="inlineBlock"
                          prop="backIdUrl">
              <el-upload :disabled="((state=='4'||state=='0'||state=='6')||companyRole!='0')?false:true"
                         class="avatar-uploader upImg"
                         action="#"
                         :auto-upload="false"
                         :on-change="(file, fileList) => {changeImgHead(file, fileList, type='2',i='22')}"
                         :show-file-list="false">
                <img v-if="form.backIdUrl"
                     :src="form.backIdUrl"
                     class="avatar">
                <div v-else
                     class="icon">
                  <em></em>
                  <i>身份证国徽页</i>
                </div>
              </el-upload>
              <a href="#javascript:;"
                 class="upImgTip"
                 @click="lookVisibleSave=true">查看示例</a>
            </el-form-item>
            <el-form-item>
              <p class="tips ml76">（请上传清晰的证照，系统会自动识别照片内信息，如信息有误可手动修改）</p>
            </el-form-item>
            <el-form-item label="手机号码："
                          prop="userPhone">
              <el-input v-model.number="form.userPhone"
                        class="input450"
                        placeholder="请输入操作员手机号码"
                        disabled></el-input>
              <el-button v-if="state=='0'||state=='4'||state=='6'"
                         @click="IdCheck(type='c')"
                         class="idBtn"
                         type="primary">身份核验
              </el-button>
            </el-form-item>
            <el-form-item label="电子邮箱："
                          prop="userEmail">
              <el-autocomplete class="input450"
                               style="width: 450px"
                               v-model="form.userEmail"
                               :fetch-suggestions="querySearch"
                               placeholder="请输入操作员电子邮箱"
                               :trigger-on-focus="false"
                               :disabled="((state=='4'||state=='0'||state=='6')||companyRole!='0')?false:true">
              </el-autocomplete>
            </el-form-item>
            <!-- <el-form-item label="测试 操作人身份证："
                          prop="certificateNumber">
              <el-input v-model="form.certificateNumber"
                        class="input450"
                        placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="测试 操作人名称："
                          prop="realName">
              <el-input v-model="form.realName"
                        class="input450"
                        placeholder=""></el-input>
            </el-form-item> -->
          </el-row>
          <el-header class="titleOne mt20">资料文件 <span v-if="state=='0'||state=='4'||companyStatus=='6'">提示：点击下载
              <a href="/static/zlbAuth.zip"
                 download="资料文件包">《资料文件包》</a>，填写信息并加盖公章后再上传</span></el-header>
          <el-row>
            <el-form-item label="电子签名授权委托书："
                          label-width="166px"
                          prop="authPdf">
              <el-upload v-if="state=='4'||state=='0'||state=='6'"
                         class="upload-demo"
                         action="#"
                         style="display: inline-block;margin-right: 10px"
                         :auto-upload="false"
                         :on-change="(file, fileList) => {upFile(file, fileList, 1)}"
                         multiple
                         :show-file-list="false"
                         :limit="3">
                <el-button class="upFileBtn"
                           size="small"
                           type="primary"><i></i>上传文件</el-button>
              </el-upload>
              <div style="display: inline-block"
                   v-if="state=='4'||state=='0'||state=='6'">
                <span v-if="!form.authPdf"
                      class="upFileTip"
                      style="margin-left: 0">未选择文件</span>
                <span v-if="form.authPdf"
                      class="upFileTip"
                      style="margin-left: 0">上传成功! <em>点击查看 <a :href="form.authPdf"
                       class="red"
                       id="link2"
                       target="_blank"><i style="word-break: break-all">《{{ authPdffileName | ellipsis }}》</i></a></em></span>
                <span v-if="collectFile1==true"
                      class="red"
                      style="margin-left: 0">上传失败，请重新上传！</span>
              </div>
              <div style="display: inline-block"
                   class="fileLook"
                   v-if="(state=='1'||state=='2'||state=='3')&&form.authPdf">
                <a :href="form.authPdf"
                   target="_blank">查看</a>
                <span class="red"
                      style="cursor: pointer"
                      @click="downFile(form.authPdf,'电子签名授权委托书')">下载</span>
              </div>
            </el-form-item>
            <!-- <el-form-item label="数字证书代办委托书："
                          label-width="166px"
                          prop="authPdfSz">
              <el-upload v-if="state=='4'||state=='0'||state=='6'"
                         class="upload-demo"
                         action="#"
                         style="display: inline-block;margin-right: 10px"
                         :auto-upload="false"
                         :on-change="(file, fileList) => {upFile(file, fileList, 2)}"
                         multiple
                         :show-file-list="false"
                         :limit="3">
                <el-button class="upFileBtn"
                           size="small"
                           type="primary"><i></i>上传文件</el-button>
              </el-upload>
              <div style="display: inline-block"
                   v-if="state=='4'||state=='0'||state=='6'">
                <span v-if="!form.authPdfSz"
                      class="upFileTip"
                      style="margin-left: 0">未选择文件</span>
                <span v-if="form.authPdfSz"
                      class="upFileTip"
                      style="margin-left: 0">上传成功! <em>点击查看<a :href="form.authPdfSz"
                       class="red"
                       id="link1"
                       target="_blank"><i style="word-break: break-all">《{{ authPdfSzfileName | ellipsis }}》</i></a></em></span>
                <span v-if="collectFile2==true"
                      class="red"
                      style="margin-left: 0">上传失败，请重新上传！</span>
              </div>
              <div style="display: inline-block"
                   class="fileLook"
                   v-if="(state=='1'||state=='2'||state=='3')&&form.authPdfSz">
                <a :href="form.authPdfSz"
                   target="_blank">查看</a>
                <span class="red"
                      style="cursor: pointer"
                      @click="downFile(form.authPdfSz,'数字证书代办委托书')">下载</span>
              </div>
            </el-form-item> -->
            <el-form-item label="法人授权委托书："
                          label-width="166px"
                          prop="collectFile">
              <el-upload v-if="state=='4'||state=='0'||state=='6'"
                         class="upload-demo"
                         action="#"
                         style="display: inline-block;margin-right: 10px"
                         :auto-upload="false"
                         :on-change="(file, fileList) => {upFile(file, fileList, 3)}"
                         :show-file-list="false"
                         multiple
                         :limit="3">
                <el-button class="upFileBtn"
                           size="small"
                           type="primary"><i></i>上传文件</el-button>
              </el-upload>
              <div style="display: inline-block"
                   v-if="state=='4'||state=='0'||state=='6'">
                <span v-if="!form.collectFile"
                      class="upFileTip"
                      style="margin-left: 0">未选择文件</span>
                <span v-if="form.collectFile"
                      class="upFileTip"
                      style="margin-left: 0">上传成功! <em>点击查看
                    <a id="link"
                       :href="form.collectFile"
                       class="red"
                       target="_blank"
                       rel="noopener noreferrer"
                       style="word-break: break-all">《{{ collectFilefileName | ellipsis }}》</a></em></span>
                <span v-if="collectFile3==true"
                      class="red"
                      style="margin-left: 0">上传失败，请重新上传！</span>
              </div>
              <div style="display: inline-block"
                   class="fileLook"
                   v-if="(state=='1'||state=='2'||state=='3')&&form.collectFile">
                <a :href="form.collectFile"
                   target="_blank">查看</a>
                <span class="red"
                      style="cursor: pointer"
                      @click="downFile(form.collectFile,'法人授权委托书')">下载</span>
              </div>
            </el-form-item>
          </el-row>
        </div>
        <div class="formBtn mt30">
          <el-button v-if="((state=='4'||state=='0'||state=='6')&&flag==false)&&companyRole=='0'"
                     class="btn normal"
                     size="small"
                     type="primary"
                     @click="addList('1')">
            保存
          </el-button>
          <el-button v-if="((state=='4'||state=='0'||state=='6')&&companyRole=='0')||companyRole !='0'"
                     class="btn submit"
                     size="small"
                     type="primary"
                     @click="addList('2')">
            提交
          </el-button>
          <el-button v-if="state=='3'&&companyRole=='0'"
                     class="btn submit"
                     size="small"
                     type="primary"
                     @click="updateList">变 更 信 息
          </el-button>
          <el-button v-if="state=='2'"
                     class="btn submit"
                     size="small"
                     type="primary"
                     @click="resetList">修 改 信 息
          </el-button>
        </div>
      </el-form>
      <!--查看示例-->
      <el-dialog :show-close="false"
                 :visible.sync="lookVisible"
                 :destroy-on-close="true"
                 width="410px"
                 class="noHeader">
        <div class="contentTip"
             style="text-align: center;line-height: 30px">
          <div class="imgBoxTip">
            <img width="100%"
                 height="100%"
                 class="tipIcon"
                 src="@/assets/img/auth/tyshxydmz_01.png"
                 alt="">
          </div>
          <h4>统一社会信用代码证（三证合一的营业执照）</h4>
          <p>（加盖公章）</p>
        </div>
      </el-dialog>

      <el-dialog :show-close="false"
                 :visible.sync="lookVisibleSave"
                 :destroy-on-close="true"
                 width="740px"
                 class="noHeader">
        <div class="contentTip"
             style="text-align: center;line-height: 30px;display: flex;justify-content: space-between">
          <div class="sfz">
            <div class="imgBoxTip">
              <img width="340px"
                   height="240px"
                   class="tipIcon"
                   src="@/assets/img/auth/frsfz1.png"
                   alt="">
            </div>
            <h4>身份证头像页</h4>
            <p>（请上传清晰的证照）</p>
          </div>
          <div class="sfz">
            <div class="imgBoxTip">
              <img width="340px"
                   height="240px"
                   class="tipIcon"
                   src="@/assets/img/auth/frsfz2.png"
                   alt="">
            </div>
            <h4>身份证国徽页</h4>
            <p>（请上传清晰的证照）</p>
          </div>
        </div>
      </el-dialog>
    </div>
    <Status v-else
            class="mt20">
      <template v-slot:statusDescribe>
        <h3 style="text-align: center">提交成功！</h3>
        <h3 style="font-size: 16px;color: #999999;font-weight: normal;line-height: 30px"
            class="operation-number"><span>请耐心等待平台审核通过</span>
        </h3>
      </template>
      <template v-slot:statusBtn>
        <el-button type="primary"
                   size="medium"
                   @click="submitAccess">完成</el-button>
      </template>
    </Status>
  </div>

</template>

<script>
import Status from "@/views/Ticket/childrenCpn/Status";
import { mapState } from "vuex";

export default {
  name: "CompanyInfor",
  components: {
    Status,
  },
  data() {
    return {
      pageType: 1,
      timeNum: 120,
      // channel:'2',//2gyl，3cpiaoju,1平台新增
      getbusinessRole: sessionStorage.getItem("businessRole"), //0小微企业 1核心企业 2保理公司 3担保公司 4律所
      state: sessionStorage.getItem("companyStatus"), //0.保存 1.审核中 2.认证失败 3.认证通过4未认证
      failureMsg: "", //失败信息
      form: {
        companyName: "", //公司名称
        socCode: "", //统一信用代码
        workProvince: "", //省
        workCity: "", //市
        workDistrict: "", //区
        workAddress: "", //详细地址
        fontBusinessUrl: "", //营业执照
        regAddress: "", //注册地址
        fontLegalUrl: "", //法人身份证正面
        backLegalUrl: "", //法人身份证反面
        businessScope: "", //营业范围
        legalIdType: "1", //法人证件类型1身份证2护照
        legalName: "", //法人姓名
        legalTel: "", //法人电话
        legalIdNo: "", //法人身份证号码
        legalNation: "", //法人民族
        isIdLong: "", //法人身份证是否长期 1是 2不是
        idStartDate: "", //法人身份证开始日期
        idEndDate: "", //法人身份证结束日期
        authPdf: "", //电子签名授权委托书
        // authPdfSz: "", //数字证书代办委托书
        userPhone: sessionStorage.getItem("getUser"), //首页带回手机号
        fontIdUrl: "", //操作人身份证正面
        backIdUrl: "", //操作人身份证反面
        certificateNation: "", //操作人民族
        userEmail: "", //邮箱
        certificateIsLong: "", //身份证证件是否长期1是2否
        certificateStartDate: "", //身份证有效期开始日期
        certificateEndDate: "", //身份证有效期结束日期
        certificateSign: "", //签发机关
        certificateAddress: "", //身份证地址
        certificateBirthday: "", //出生日期
        collectFile: "", //法人授权委托文件
        certificateNumber: "", //身份证号
        realName: "", //姓名
        coreCompanyName: "", //核心企业
        legalSign: "", //法人签发机关
        legalAddress: "", //法人身份证地址
        legalBirthday: "", //法人出生日期
      },
      coreName: [], //核心企业下拉
      province: [], //省
      city: [], //市
      district: [], //区
      commitState: false, //提交成功页面
      lookVisible: false, //身份证查看示例
      lookVisibleSave: false, //营业执照查看示例
      collectFile1: false, //电子签名授权委托书失败提示
      // collectFile2: false, //数字证书代办委托书失败提示
      collectFile3: false, //法人授权委托文件失败提示
      flag: false, //变更开关
      flagSave: false, //保存开关
      collectFilefileName: "", //法人授权委托文件
      authPdffileName: "", //电子签名授权委托书
      // authPdfSzfileName: "", //数字证书代办委托书
      companyId: "", //企业id
      setProvince: "", //重置省
      setCity: "", //重置市
      setDistrict: "", //重置区
      setProvinceid: "", //省id
      setCityid: "", //市id
      setDistrictid: "", //区id
      emailArry: [
        //邮箱联想
        "@qq.com",
        "@sina.com",
        "@163.com",
        "@126.com",
        "@yahoo.com.cn",
        "@gmail.com",
        "@sohu.com",
      ],
      rules: {
        fontBusinessUrl: [
          { required: true, message: "此项必填", trigger: "change" },
        ],
        companyName: [{ required: true, message: "此项必填", trigger: "blur" }],
        socCode: [{ required: true, message: "此项必填", trigger: "blur" }],
        coreCompanyName: [
          { required: true, message: "此项必填", trigger: "change  " },
        ],
        workProvince: [
          { required: true, message: "此项必填", trigger: "blur" },
        ],
        fontLegalUrl: [
          { required: true, message: "此项必填", trigger: "change" },
        ],
        backLegalUrl: [
          { required: true, message: "此项必填", trigger: "change" },
        ],
        legalTel: [
          { required: true, message: "此项必填", trigger: "blur" },
          {
            type: "number",
            message: "手机号格式不正确",
            trigger: "blur",
            transform(value) {
              let phonereg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/;
              if (!phonereg.test(value)) {
                return false;
              } else {
                return Number(value);
              }
            },
          },
        ],
        fontIdUrl: [{ required: true, message: "此项必填", trigger: "change" }],
        backIdUrl: [{ required: true, message: "此项必填", trigger: "change" }],
        userPhone: [
          { required: true, message: "此项必填", trigger: "blur" },
          {
            type: "number",
            message: "手机号格式不正确",
            trigger: "blur",
            transform(value) {
              let phonereg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/;
              if (!phonereg.test(value)) {
                return false;
              } else {
                return Number(value);
              }
            },
          },
        ],
        userEmail: [
          { required: true, message: "此项必填", trigger: "blur" },
          {
            validator: function (rule, value, callback) {
              let Email = /^\w{1,64}@[a-z0-9\-]{1,256}(\.[a-z]{2,6}){1,2}$/i;
              if (Email.test(value) == false) {
                callback(new Error("邮箱格式不正确"));
              } else {
                callback();
              }
            },
          },
        ],
        authPdf: [{ required: true, message: "此项必填", trigger: "change" }],
        collectFile: [
          { required: true, message: "此项必填", trigger: "change" },
        ],
        authPdfSz: [{ required: true, message: "此项必填", trigger: "change" }],
      },
      workProvinceName: "", //省回显
      workCityName: "", //市回显
      workDistrictName: "", //区回显
      userId: "", //用户id
      checkFlag: false, //身份核验开关
    };
  },
  computed: {
    ...mapState([
      "companyStatus", //企业状态
      "getUser", //用户手机号
      "companyRole", //公司角色
      "backStatus", //带回状态
    ]),
  },
  created() {
    this.getProvince();
    this.getDetail();
  },
  filters: {
    //文件名称超出20个字...隐藏
    ellipsis(value) {
      if (!value) return "";
      if (value.length > 20) {
        return value.slice(0, 20) + "... " + ".pdf";
      }
      return value;
    },
  },
  methods: {
    //新增
    async addList(i) {
      var pathFile; //路径
      var shen, shi, qu;
      if (this.form.workProvince == this.workProvinceName) {
        shen = this.setProvinceid; //省
      } else {
        shen = this.form.workProvince; //省
      }
      if (this.form.workCity == this.workCityName) {
        shi = this.setCityid; //省
      } else {
        shi = this.form.workCity; //省
      }
      if (this.form.workDistrict == this.workDistrictName) {
        qu = this.setDistrictid; //省
      } else {
        qu = this.form.workDistrict; //省
      }
      if (i == "1") {
        //保存不校验
        pathFile = "/cpiaoju-auth/lxgw/front/company/addCompany";
        this.flagSave = true; //保存开启  if (this.companyId) {
        let comStatus;
        if (this.companyStatus == "2") {
          comStatus = "2";
        } else if (this.companyStatus == "6") {
          comStatus = "6";
        } else if (this.companyStatus == "3") {
          comStatus = "3";
        } else {
          comStatus = "0";
        }
        let obj = {
          status: comStatus,
          id: this.companyId,
          userId: this.userId,
          companyName: this.form.companyName, //公司名称
          socCode: this.form.socCode, //统一信用代码
          workProvince: shen, //省
          workCity: shi, //市
          workDistrict: qu, //区
          workAddress: this.form.workAddress, //详细地址
          fontBusinessUrl: this.form.fontBusinessUrl, //营业执照
          regAddress: this.form.regAddress, //注册地址
          fontLegalUrl: this.form.fontLegalUrl, //法人身份证正面
          backLegalUrl: this.form.backLegalUrl, //法人身份证反面
          businessScope: this.form.businessScope, //营业范围
          legalIdType: this.form.legalIdType, //法人证件类型1身份证2护照
          legalNation: this.form.legalNation, //法人民族
          legalName: this.form.legalName, //法人姓名
          legalTel: this.form.legalTel, //法人电话
          legalIdNo: this.form.legalIdNo, //法人身份证号码
          isIdLong: this.form.isIdLong, //法人身份证是否长期 1是 2不是
          idStartDate: this.form.idStartDate, //法人身份证开始日期
          idEndDate: this.form.idEndDate, //法人身份证结束日期
          authPdf: this.form.authPdf, //电子签名授权委托书
          // authPdfSz: this.form.authPdfSz, //数字证书代办委托书
          userPhone: sessionStorage.getItem("getUser"), //首页带回手机号
          fontIdUrl: this.form.fontIdUrl, //操作人身份证正面
          backIdUrl: this.form.backIdUrl, //操作人身份证反面
          userEmail: this.form.userEmail, //邮箱
          certificateIsLong: this.form.certificateIsLong, //身份证证件是否长期1是2否
          certificateStartDate: this.form.certificateStartDate, //身份证有效期开始日期
          certificateEndDate: this.form.certificateEndDate, //身份证有效期结束日期
          certificateSign: this.form.certificateSign, //签发机关
          certificateAddress: this.form.certificateAddress, //身份证地址
          certificateNation: this.form.certificateNation, //操作人民族
          certificateBirthday: this.form.certificateBirthday, //出生日期
          collectFile: this.form.collectFile, //法人授权委托文件
          certificateNumber: this.form.certificateNumber, //身份证号
          realName: this.form.realName, //姓名
          coreCompanyName: this.form.coreCompanyName, //核心企业
          legalSign: this.form.legalSign, //签发机关  --------------------------------------------
          legalAddress: this.form.legalAddress, //身份证地址
          legalBirthday: this.form.legalBirthday, //出生日期
        };
        const { data: res } = await this.$auth.post(
          pathFile,
          this.$qs.stringify(obj)
        );
        if (res.code == 200) {
          if (this.companyStatus == "0") {
            this.$store.state.companyStatus = "0";
            this.$store.commit("getcompanyStatus", "0");
          }
          await this.getDetail();
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      } else {
        this.$refs.form.validate(async (valid) => {
          if (!valid) return;
          if (i == "2") {
            //提交
            console.log(this.form.state, "提交的状态");
            pathFile = "/cpiaoju-auth/lxgw/front/company/updateCompany";
            let Isstatus, IsSupply;
            if (this.form.isSupply == "1" && this.companyStatus == "3") {
              Isstatus = "1";
              IsSupply = "2";
            } else if (this.companyStatus == "6") {
              Isstatus = "1";
              // IsSupply = '2';
            } else {
              Isstatus = "1";
            }
            let obj1 = {
              // status: '1',
              status: Isstatus,
              isSupply: IsSupply,
              id: this.companyId,
              userId: this.userId,
              companyName: this.form.companyName, //公司名称
              socCode: this.form.socCode, //统一信用代码
              workProvince: shen, //省
              workCity: shi, //市
              workDistrict: qu, //区
              workAddress: this.form.workAddress, //详细地址
              fontBusinessUrl: this.form.fontBusinessUrl, //营业执照
              regAddress: this.form.regAddress, //注册地址
              fontLegalUrl: this.form.fontLegalUrl, //法人身份证正面
              backLegalUrl: this.form.backLegalUrl, //法人身份证反面
              businessScope: this.form.businessScope, //营业范围
              legalIdType: this.form.legalIdType, //法人证件类型1身份证2护照
              legalName: this.form.legalName, //法人姓名
              legalTel: this.form.legalTel, //法人电话
              legalIdNo: this.form.legalIdNo, //法人身份证号码
              isIdLong: this.form.isIdLong, //法人身份证是否长期 1是 2不是
              idStartDate: this.form.idStartDate, //法人身份证开始日期
              idEndDate: this.form.idEndDate, //法人身份证结束日期
              legalNation: this.form.legalNation, //操作人民族
              authPdf: this.form.authPdf, //电子签名授权委托书
              // authPdfSz: this.form.authPdfSz, //数字证书代办委托书
              userPhone: sessionStorage.getItem("getUser"), //首页带回手机号
              fontIdUrl: this.form.fontIdUrl, //操作人身份证正面
              backIdUrl: this.form.backIdUrl, //操作人身份证反面
              userEmail: this.form.userEmail, //邮箱
              certificateIsLong: this.form.certificateIsLong, //身份证证件是否长期1是2否
              certificateStartDate: this.form.certificateStartDate, //身份证有效期开始日期
              certificateEndDate: this.form.certificateEndDate, //身份证有效期结束日期
              certificateNation: this.form.certificateNation, //操作人民族
              certificateSign: this.form.certificateSign, //签发机关
              certificateAddress: this.form.certificateAddress, //身份证地址
              certificateBirthday: this.form.certificateBirthday, //出生日期
              collectFile: this.form.collectFile, //法人授权委托文件
              certificateNumber: this.form.certificateNumber, //身份证号
              realName: this.form.realName, //姓名
              coreCompanyName: this.form.coreCompanyName, //核心企业
              legalSign: this.form.legalSign, //签发机关  --------------------------------------------
              legalAddress: this.form.legalAddress, //身份证地址
              legalBirthday: this.form.legalBirthday, //出生日期
            };
            const { data: res } = await this.$auth.post(
              pathFile,
              this.$qs.stringify(obj1)
            );
            if (res.code == 200) {
              if (this.companyRole != "0") {
                await this.getDetail();
              } else {
                this.commitState = true; //提交成功页面开启
              }
              this.$message.success(res.msg);
            } else {
              this.$message.error(res.msg);
            }
          }
        });
      }
    },
    //变更
    updateList() {
      this.state = "0";
      this.flag = true;
    },
    //修改信息
    resetList() {
      this.state = "0";
    },
    //身份验证
    async IdCheck(val) {
      let obj = {};
      let FfontLegalUrl = false; //法人身份证正面
      let FlegalTel = false; //法人手机号
      let CfontIdUrl = false; //操作人身份证正面
      let FcompanyName = false; //企业名称
      if (val == "f") {
        this.$refs["form"].validateField("companyName", async (vaild) => {
          if (!vaild) {
            FcompanyName = true;
            return true;
          } else {
            FcompanyName = false;
            return false;
          }
        });
        this.$refs["form"].validateField("fontLegalUrl", async (vaild) => {
          if (!vaild) {
            FfontLegalUrl = true;
            return true;
          } else {
            FfontLegalUrl = false;
            return false;
          }
        });
        this.$refs["form"].validateField("legalTel", async (vaild) => {
          if (!vaild) {
            FlegalTel = true;
            return true;
          } else {
            FlegalTel = false;
            return false;
          }
        });
        if (
          FcompanyName == true &&
          FfontLegalUrl == true &&
          FlegalTel == true
        ) {
          obj.companyName = this.form.companyName; //法人姓名
          obj.name = this.form.legalName; //法人姓名
          obj.phone = this.form.legalTel;
          obj.idCard = this.form.legalIdNo;
          obj.type = val;
        } else {
          return false;
        }
      } else if (val == "c") {
        this.$refs["form"].validateField("fontIdUrl", async (vaild) => {
          if (!vaild) {
            CfontIdUrl = true;
            return true;
          } else {
            CfontIdUrl = false;
            return false;
          }
        });
        if (CfontIdUrl == true) {
          obj.name = this.form.realName;
          obj.phone = this.form.userPhone;
          obj.idCard = this.form.certificateNumber;
          obj.companyName = this.form.companyName;
          obj.type = val;
          obj.pageType = this.pageType;
        } else {
          return false;
        }
      }

      if (this.pageType == 1) {
        const { data: res } = await this.$auth.post(
          "/cpiaoju-auth/mobileCheck",
          this.$qs.stringify(obj)
        );
        if (res.code == 200) {
          this.pageType = 2;
          this.$message.success("核验通过，已发送信息！");
        } else {
          this.$message.error(res.msg);
        }
      } else {
        // 信息对比 如果想通传2和信息超过两分钟可传状态为1 如果不相同不传2 继续发送短息
        if (
          this.form.legalTel != this.form.userPhone ||
          this.form.legalName != this.form.realName ||
          this.form.legalIdNo != this.form.certificateNumber
        ) {
          delete obj.pageType;
          const { data: res } = await this.$auth.post(
            "/cpiaoju-auth/mobileCheck",
            this.$qs.stringify(obj)
          );

          if (res.code == 200) {
            this.$message.success("核验通过，已发送信息！");
          } else {
            this.$message.error(res.msg);
          }
        } else {
          let timer = setInterval(() => {
            this.delayTime = this.timeNum - 1;
            if (this.timeNum < 0) {
              clearInterval(timer);
              // this.pageType == 1;
              this.delayTime = 120;
            }
          }, 1000);
          obj.pageType = 2;
          const { data: res } = await this.$auth.post(
            "/cpiaoju-auth/mobileCheck",
            this.$qs.stringify(obj)
          );
          if (res.code == 200) {
            this.$message.success("核验通过，已发送信息！");
          } else {
            this.$message.error(res.msg);
          }
        }
      }
    },
    // 详情
    async getDetail() {
      const { data: res } = await this.$auth.get(
        "/cpiaoju-auth/lxgw/front/company/getInfo"
      );
      if (res.code == 200) {
        if (res.data == "" || res.data == null) {
          this.state = "4"; //默认为未认证 0.保存 1.待认证 2.认证失败 3.认证通过4未认证
          this.$store.state.companyStatus = "4";
          this.$store.commit("getcompanyStatus", 4);
        } else {
          if (this.state == "1" || this.state == "2" || this.state == "3") {
            this.failureMsg = res.data.failureMsg; //失败信息
          }
          this.state = res.data.status; //认证状态
          sessionStorage.setItem("companyStatus", res.data.status);
          this.$store.state.companyStatus = res.data.status;
          this.$store.commit("getcompanyStatus", res.data.status);
          this.$store.state.companyName = res.data.companyName;
          this.$store.commit("getcompanyName", res.data.companyName);
          this.userId = res.data.userId; //用户id
          this.form.isSupply = res.data.isSupply; //是否补充信息
          this.form.workCity = res.data.workCityName;
          this.form.workDistrict = res.data.workDistrictName;
          this.form.workProvince = res.data.workProvinceName;
          this.workCityName = res.data.workCityName;
          this.workDistrictName = res.data.workDistrictName;
          this.workProvinceName = res.data.workProvinceName;
          this.setProvinceid = res.data.workProvince;
          this.setCityid = res.data.workCity;
          this.setDistrictid = res.data.workDistrict;
          this.companyId = res.data.id;
          this.form.companyName = res.data.companyName;
          this.form.socCode = res.data.socCode;
          this.form.workAddress = res.data.workAddress;
          this.form.fontBusinessUrl = res.data.fontBusinessUrl; //营业执照
          this.form.regAddress = res.data.regAddress; //注册地址
          this.form.fontLegalUrl = res.data.fontLegalUrl; //法人身份证正面
          this.form.backLegalUrl = res.data.backLegalUrl; //法人身份证反面
          this.form.businessScope = res.data.businessScope; //营业范围
          this.form.legalIdType = res.data.legalIdType; //法人证件类型1身份证2护照
          this.form.legalName = res.data.legalName; //法人姓名
          this.form.legalTel = res.data.legalTel;
          this.form.legalIdNo = res.data.legalIdNo; //法人身份证号码
          this.form.isIdLong = res.data.isIdLong; //法人身份证是否长期 1是 2不是
          this.form.idStartDate = res.data.idStartDate; //法人身份证开始日期
          this.form.idEndDate = res.data.idEndDate; //法人身份证结束日期
          this.form.legalSign = res.data.legalSign; //法人签发机关
          this.form.legalAddress = res.data.legalAddress; //法人身份证地址
          this.form.legalBirthday = res.data.legalBirthday; //法人出生日期
          this.form.authPdf = res.data.authPdf;
          this.form.authPdfSz = res.data.authPdfSz;
          this.form.userPhone = window.sessionStorage.getItem("getUser"); //首页带回
          this.form.fontIdUrl = res.data.fontIdUrl; //操作人身份证正面
          this.form.backIdUrl = res.data.backIdUrl; //操作人身份证反面
          this.form.userEmail = res.data.userEmail; //邮箱
          this.form.certificateIsLong = res.data.certificateIsLong; //身份证证件是否长期1是2否
          this.form.certificateStartDate = res.data.certificateStartDate; //身份证有效期开始日期
          this.form.certificateEndDate = res.data.certificateEndDate; //身份证有效期结束日期
          this.form.certificateSign = res.data.certificateSign; //签发机关
          this.form.certificateAddress = res.data.certificateAddress; //身份证地址
          this.form.certificateBirthday = res.data.certificateBirthday; //出生日期
          this.form.certificateNumber = res.data.certificateNumber; //身份证号
          this.form.realName = res.data.realName; //姓名

          if (res.data.authPdf == "" || res.data.authPdf == null) {
            this.authPdffileName = ""; //电子签名授权文件
          } else {
            let authPdf = res.data.authPdf;
            let authPdf1 = authPdf.substring(authPdf.lastIndexOf("/") + 1);
            this.authPdffileName = authPdf1; //电子签名授权文件
          }
          this.form.authPdf = res.data.authPdf; //电子签名授权文件
          // if (res.data.authPdfSz == "" || res.data.authPdfSz == null) {
          //   this.authPdfSzfileName = ""; //数字证书文件
          // } else {
          //   let authPdfSz = res.data.authPdfSz;
          //   let authPdfSz1 = authPdfSz.substring(
          //     authPdfSz.lastIndexOf("/") + 1
          //   );
          //   this.authPdfSzfileName = authPdfSz1; //数字证书文件
          // }
          // this.form.authPdfSz = res.data.authPdfSz; //数字证书文件
          if (res.data.collectFile == "" || res.data.collectFile == null) {
            this.collectFilefileName = ""; //法人授权委托文件
          } else {
            let collectFile = res.data.collectFile;
            let collectFile1 = collectFile.substring(
              collectFile.lastIndexOf("/") + 1
            );
            this.collectFilefileName = collectFile1; //法人授权委托文件
          }
          this.form.collectFile = res.data.collectFile; //法人授权委托文件
          this.coreName = res.data.coreName; //核心企业下拉
          this.form.coreCompanyName = res.data.coreCompanyName; //核心企业
        }
      }
    },
    // 获取核心企业
    async getCoreCompany(val) {
      const { data: res } = await this.$auth.post(
        "/cpiaoju-auth/lxgw/front/company/getCoreName?coreName=" + val
      );
      this.coreName = res.data.records;
    },
    //上传操作人证件isIdLong
    changeImgHead(file, fileList, type, i) {
      // console.log(type, i, '--------')
      var testMsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testMsg === "png";
      const extension2 = testMsg === "jpg";
      const extension3 = testMsg === "jpeg";
      const isLt4M = file.size / 1024 / 1024 < 4;
      // 判断图片的格式
      if (!extension && !extension2 && !extension3) {
        this.$message({
          message: "上传文件只能是 png、jpg、jpeg格式!",
          type: "warning",
        });
        fileList.pop(); //用于删除并返回数组的最后一个元素。
        return;
      }
      // 判断图片的大小
      if (!isLt4M) {
        this.$message({
          message: "上传文件大小不能超过 4M!",
          type: "warning",
        });
        fileList.pop();
        return;
      }
      var idCardSide;
      if (type == "2" && (i == "1" || i == "21")) {
        idCardSide = "front";
      } else {
        idCardSide = "back";
      }
      let paramList = new FormData();
      paramList.append("file", file.raw);
      paramList.append("type", type);
      if (type !== "1") {
        paramList.append("idCardSide", idCardSide);
      }
      try {
        this.$auth.post("/cpiaoju-auth/ocr/upload", paramList).then((res) => {
          if (res.data.code == 200 && res.data.ocr.status == "1") {
            if (type == "1") {
              //营业执照
              if (res.data.ocr.unit_name) {
                this.form.fontBusinessUrl = res.data.fileUrl; //营业执照
                this.form.regAddress = res.data.ocr.address; //注册地址
                this.form.businessScope = res.data.ocr.business_scope; //注册地址
                this.form.companyName = res.data.ocr.unit_name; //企业名称
                this.form.socCode = res.data.ocr.social_credit_code; //统一信用代码
              } else {
                this.$message.error("您上传图片无法识别，请重新上传");
              }
            } else if (type == "2" && i == "1") {
              //法人正面
              this.form.fontLegalUrl = res.data.fileUrl;
              this.form.legalName = res.data.ocr.full_name; //法人姓名
              this.form.legalIdNo = res.data.ocr.citizenship_number; //法人身份证号码
              this.form.legalAddress = res.data.ocr.address; //法人身份证地址
              this.form.legalNation = res.data.ocr.nation; //法人民族
              this.form.legalBirthday = this.$moment(
                res.data.ocr.birth,
                "YYYYMMDD"
              ).format("YYYY-MM-DD"); //法人出生日期
            } else if (type == "2" && i == "2") {
              //法人反面
              let timeStart = res.data.ocr.issue_date;
              let timeEnd = res.data.ocr.expiring_date;
              if (timeEnd == "" || timeEnd == null) {
                this.form.isIdLong = "1"; //身份证证件是否长期1是2否
              } else {
                var formatTimeStart = this.$moment(
                  timeStart,
                  "YYYYMMDD"
                ).format("YYYY-MM-DD");
                var formatTimeEnd = this.$moment(timeEnd, "YYYYMMDD").format(
                  "YYYY-MM-DD"
                );
                this.form.isIdLong = "2"; //身份证证件是否长期1是2否
              }
              this.form.backLegalUrl = res.data.fileUrl;
              this.form.idStartDate = formatTimeStart; //法人身份证开始日期
              this.form.idEndDate = formatTimeEnd; //法人身份证结束日期
              this.form.legalSign = res.data.ocr.organization; //法人签发机关
            } else if (type == "2" && i == "21") {
              //操作人正面
              this.form.fontIdUrl = res.data.fileUrl;
              this.form.certificateAddress = res.data.ocr.address; //身份证地址
              this.form.certificateNation = res.data.ocr.nation; //操作人民族
              this.form.certificateBirthday = this.$moment(
                res.data.ocr.birth,
                "YYYYMMDD"
              ).format("YYYY-MM-DD"); //出生日期----
              this.form.certificateNumber = res.data.ocr.citizenship_number; //身份证号
              this.form.realName = res.data.ocr.full_name; //姓名
            } else if (type == "2" && i == "22") {
              //操作人反面
              let timeStartCe = res.data.ocr.issue_date;
              let timeEndCe = res.data.ocr.expiring_date;
              if (timeStartCe == "" || timeStartCe == null) {
                this.form.certificateIsLong = "1"; //身份证证件是否长期1是2否
              } else {
                var formatTimeStartCe = this.$moment(
                  timeStartCe,
                  "YYYYMMDD"
                ).format("YYYY-MM-DD");
                var formatTimeEndCe = this.$moment(
                  timeEndCe,
                  "YYYYMMDD"
                ).format("YYYY-MM-DD");
                this.form.certificateIsLong = "2"; //身份证证件是否长期1是2否
              }
              this.form.backIdUrl = res.data.fileUrl;
              this.form.certificateSign = res.data.ocr.organization; //签发机关
              this.form.certificateStartDate = formatTimeStartCe; //身份证有效期开始日期
              this.form.certificateEndDate = formatTimeEndCe; //身份证有效期结束日期
            }
          } else {
            this.$message.error("您上传图片无法识别，请重新上传");
          }
        });
      } catch (erro) {
        this.$message.error("您上传图片无法识别，请重新上传");
      }
    },
    //上传文件
    upFile(file, fileList, type) {
      var testMsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testMsg === "pdf";
      const isLt4M = file.size / 1024 / 1024 < 4;
      // 判断图片的格式
      if (!extension) {
        this.$message({
          message: "上传文件只能是 pdf 格式!",
          type: "warning",
        });
        fileList.pop(); //用于删除并返回数组的最后一个元素。
        return;
      }
      // 判断图片的大小
      if (!isLt4M) {
        this.$message({
          message: "上传文件大小不能超过 4M!",
          type: "warning",
        });
        fileList.pop();
        return;
      }
      let paramList = new FormData();
      paramList.append("file", file.raw);
      this.$auth
        .post("/cpiaoju-auth/ocr/uploadFile", paramList)
        .then((res) => {
          if (res.data.code == 200) {
            if (type == 1) {
              this.form.authPdf = res.data.fileUrl; //电子签名认证委托书
              this.authPdffileName = res.data.fileName; //电子签名认证委托书名称
            } else if (type == 2) {
              // this.form.authPdfSz = res.data.fileUrl; //数字证书代办委托书
              // this.authPdfSzfileName = res.data.fileName; //数字证书代办委托书名称
            } else if (type == 3) {
              this.form.collectFile = res.data.fileUrl; //法人授权委托文件
              this.collectFilefileName = res.data.fileName; //法人授权委托文件名称
            }
          } else {
            this.collectFile = true;
            this.form.collectFile = "";
            this.$message.error(res.data.ocr.message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.collectFile = true;
          this.$message.error("文件上传错误！");
        });
    },
    // 邮箱联想
    querySearch(queryString, cb) {
      let results = [];
      if (!queryString) return [];
      if (queryString.indexOf("@") > -1) {
        results = [];
        cb(results);
      } else {
        this.emailArry.forEach((item) => {
          results.push({
            value: queryString + item,
          });
        });
        cb(results);
      }
    },
    //获取省
    async getProvince() {
      const { data: res } = await this.$auth.post(
        "/cpiaoju-auth/areaCode/getProvList"
      );
      this.province = res.data;
    },
    //获取市
    async getCity(val) {
      if (this.form.workCity) {
        this.form.workCity = "";
        this.city = [];
      }
      if (this.form.workDistrict) {
        this.form.workDistrict = "";
        this.district = [];
      }
      const { data: res } = await this.$auth.post(
        "/cpiaoju-auth/areaCode/getCityByProvinceId?pid=" + val
      );
      this.city = res.data;
      // this.district=[]
    },
    //获取区
    async getDistrict(val) {
      if (this.form.workDistrict) {
        this.form.workDistrict = "";
        this.district = [];
      }
      const { data: res } = await this.$auth.post(
        "/cpiaoju-auth/areaCode/getCityByProvinceId?pid=" + val
      );
      this.district = res.data;
    },
    //下载pdf
    downFile(file, name) {
      let rowFileType = file.substring(file.lastIndexOf(".") + 1);
      if (["pdf"].includes(rowFileType)) {
        this.$auth({
          url: "/cpiaoju-auth/ocr/downloadUrlFileByName",
          method: "get",
          responseType: "blob",
          params: {
            url: file,
            fileName: name,
          },
        }).then((res) => {
          const blobPdf = new Blob([res.data], {
            type: "application/pdf",
          });
          const href = URL.createObjectURL(blobPdf); //创建新的URL表示指定的blob对象
          const a = document.createElement("a"); //创建a标签
          a.style.display = "none";
          a.href = href; // 指定下载链接
          a.download = name; //指定下载文件名
          a.click(); //触发下载
          URL.revokeObjectURL(a.href); //释放URL对象
        });
      }
    },
    //提交成功页-返回列表
    submitAccess() {
      this.commitState = false;
      this.getDetail();
    },
  },
  watch: {
    backStatus: function (val) {
      console.log(val, "0");
      if (this.backStatus == "2") {
        this.getDetail();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.company {
  /deep/ .avatar-uploader .el-upload {
    position: relative;
    width: 150px;
    height: 150px;
    border: 1px dashed #d8d8d8;
    text-align: center;
  }

  .cardInfo {
    position: relative;

    .el-row {
      padding: 20px 20px 0;
      box-sizing: border-box;
      background: #ffffff;
    }
  }

  .upImg {
    display: inline-block;

    .icon {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-top: 40px;

      em {
        width: 36px;
        height: 36px;
        background: url("~@/assets/img/auth/tianjia_icon.png") no-repeat;
      }

      i {
        color: #999999;
        letter-spacing: 1px;
      }
    }
  }

  .upImgTip {
    margin-left: 12px;
    color: #c70009;
  }

  p.tips {
    color: #999999;
    line-height: 20px;
  }

  /deep/ .el-form-item__label {
    /*width: auto;*/
    /*text-align: left;*/
  }

  .el-input.input394 {
    width: 394px;
  }

  .adress {
    /deep/ .el-select {
      width: 143px;
      margin-right: 10px;

      .el-input {
        width: 100%;
      }
    }

    /deep/ .el-input {
      width: 200px;
    }
  }

  .el-form-item.inlineBlock {
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 0;

    /deep/ .el-form-item__content {
      display: inline-block;
      margin-left: 0 !important;
      height: 152px;
      margin-bottom: 16px;
    }
  }

  .ml76 {
    /*margin-left: 76px;*/
  }

  .upFileTip {
    color: #999999;
    margin-left: 10px;
  }

  /*按钮*/

  .upFileBtn {
    background: #ffffff;
    color: #c70009;
    border-color: #c70009;
    font-size: 14px;

    i {
      display: inline-block;
      width: 16px;
      height: 14px;
      background: url("~@/assets/img/auth/sc_02.png") no-repeat center;
      margin-right: 4px;
    }
  }

  .upFileBtn:hover {
    background: #c70009;
    border-color: #c70009;
    color: #ffffff;

    i {
      background: url("~@/assets/img/auth/sc_01.png") no-repeat center;
    }
  }

  .infoState {
    position: absolute;
    right: 100px;
  }
}

.fileLook {
  a {
    color: #c70009;
    margin-right: 20px;
  }
}

.failTip {
  margin-top: 20px;
  width: 100%;
  height: auto;
  min-height: 40px;
  word-break: break-all;
  background: #ffe6e5;
  border: 1px solid #c70009;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 1px;
  padding: 8px 20px;
  box-sizing: border-box;
}
</style>
<style>
.company .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.company .avatar-uploader .el-upload:hover {
  /*border-color: #409EFF;*/
}

.company .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 150px;
  height: 150px;
  line-height: 150px;
  text-align: center;
}

.company .avatar {
  width: 150px;
  height: 150px;
  display: block;
}

.noHeader .el-dialog__header {
  padding: 0 !important;
}

.hintBox {
  width: 450px;
  max-height: 140px;
  overflow-y: scroll;
  overflow-x: auto;
  position: absolute;
  z-index: 999;
  background-color: #fff;
  /*border: 1px solid #999999;*/
  /*border-top: none;*/
}

.hintItem {
  margin-top: 2px;
  cursor: pointer;
  margin-left: 10px;
  font-size: 13px;
  color: #898989;
}
</style>